<template>

  <div class="card card-custom gutter-b example example-compact"> 
    <div class="card-header">
      <div class="card-title">
        <h3>Server Action Log</h3>
      </div>
    </div>
    <!--Dropdown List-->
    <div class="card-body">
      <div class="row">
        <div class="col-sm-2">
          <label class="control-label">Sites</label>
          <select class="custom-select" name="site" v-model="selectSite" v-on:change="ddlFilter()">
            <option v-for="(item, key) in sites" :key="key" v-bind:value="item.id">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-sm-2">
          <label class="control-label">Banks</label>
          <select class="custom-select" name="selectBank" v-model="selectBank" v-on:change="ddlFilter()">
            <option v-for="(item, key) in banks" :key="key" v-bind:value="item.id">
              {{item.bank_code}}
            </option>
          </select>
        </div>
        <div class="col-sm-2">
          <label class="control-label">Bank Accounts</label>
          <select class="custom-select" name="selectBankAcc" v-model="selectBankAcc" v-if="bankAccs.length">
            <option v-for="(item, key) in bankAccs" :key="key" v-bind:value="item.id" >
              {{item.account_name}} - {{ item.account_number }}
            </option>
          </select>   
          <select class="custom-select" name="selectBankAcc" v-model="selectBankAcc" v-else>
            <option disabled="true">
              No Record Found
            </option>
          </select>    
        </div>
        <div class="col-sm-2">
          <label class="control-label">Level</label>
          <select class="form-control" name="selectLevel" v-model="selectLevel">
            <option value="1">
              1 - Normal
            </option>
            <option value="2">
              2 - Medium
            </option>
            <option value="3">
              3 - High
            </option>
          </select>   
        </div>
        <div class="col-sm-2">
          <label class="control-label">Transfer Type</label>
          <select class="form-control" name="selectTransferType" v-model="selectTransferType">
            <option value="1">
              1 - Deposit
            </option>
            <option value="2">
              2 - Withdrawal
            </option>
          </select>   
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-4">
          <label class="control-label">Choose a date</label>
          <b-form-datepicker id="example-datepicker" v-model="dateValue" class="mb-2"></b-form-datepicker>
        </div>
      </div>
      <div>
        <br>
        <b-button v-on:click="getActionLogs(currentPage)">Search</b-button>
        <b-button v-on:click="clear">Clear</b-button>
    </div>
    

  <!--Table Result-->


    <div class="overflow-auto" v-if="actionLogs.length">    
       <div v-if="checkSwitch == '0'">
         <br/>
         <b-alert show variant="info">The selected bank account is currently OFF by operator from {{updateDate}}.</b-alert>
       </div>
      <b-table
      id="actionLogTable"
      striped hover
      :items="actionLogs"
      :fields="headers"
      ></b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <div class="overflow-atuo"  v-else>
      <br>
      <p> No Record Found.</p>
    </div>
  
   <p>  </p>
  </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import moment from 'moment';

export default {
  name: "serverLog",
  data(){
      return{
          info: '',
          banks: [],
          sites: [],
          bankAccs:[],
          headers: [
            "site", "bank", 'username','log_level','transfer_type','message',
            {
              key: 'log_created_date',
              sortable: true
            }
          ],
          actionLogs: [],
          response:[],
          perPage: '',
          currentPage:1,
          rows:'',
          dateValue:'',
          selectSite:'',
          selectBank:'',
          selectBankAcc:'',
          selectLevel:'',
          selectTransferType:'',
          checkSwitch:'',
          updateDate:'',
      };
  },
  //Get sites and banks list data from api for dropdown list
  created(){
    ApiService.get("banks")
   .then(({ data }) => {
      this.banks = data.data.banks;
    })
    .catch(error => {
      console.log("getAllBank() error", error.response);
    });
      
    ApiService.get("sites")
    .then(({ data }) => {
      this.sites = data.data.sites;
    })
    .catch(error => {
      console.log("getAllSite() error", error.response);
    });
  },
  mounted() {
    //initial load
    this.getActionLogs(this.currentPage)
  },
  //get on change pagination value
  watch: {
        currentPage: function(val){
          this.getActionLogs(val)
        }
  },
  methods: {
      // async showText(){
      //     const info = await this.$http.get(baseURL+'/bankAccs',{
      //      params: {
      //           'bankId': this.selectBank,
      //           'siteId': this.selectSite
      //       },
      //       headers:{
      //             'Accept' : 'application/json',
      //             'Access-Control-Allow-Origin' : '*',
      //             'X-ACCESS-KEY' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.2bY0ZFhH_GvEBsPXALQA93XXsjh-apjqnbBDcX7Fwls',
      //             'Content-Type' : 'application/json',
      //             'Cache-Control': null,
      //             'X-Requested-With': null
      //         }
      //     });
      //     this.info = info.data.data.bankAccs;
      // },
      //Filter for bank accounts dropdown list
      ddlFilter() {
        ApiService.query("bankAccs",{
          params: {
            'bankId': this.selectBank,
            'siteId': this.selectSite
          }
        })
        .then(({ data }) => {
          this.bankAccs = data.data.bankAccs;
        })
        .catch(error => {
          console.log("getAllBankAcc() error", error.response);
        });
      },
      getActionLogs(currentPage){
        ApiService.query("logActions",{
          params: {
            'page' :currentPage,
            'bankId': this.selectBank,
            'siteId': this.selectSite,
            'accountId' : this.selectBankAcc,
            'log_level' : this.selectLevel,
            'createDate' : this.dateValue,
            'transfer_type' : this.selectTransferType
          }
        })
        .then(({ data }) => {
          this.actionLogs = data.data.actionsLog.data;
          this.perPage = data.data.actionsLog.per_page;
          this.rows = data.data.actionsLog.total;
          if(typeof(data.data.actionsLog.switch) !== 'undefined'){
            this.checkSwitch = data.data.actionsLog.switch;
          }
          if(typeof(data.data.actionsLog.updated_at) !== 'undefined'){
            this.updateDate = moment(String(data.data.actionsLog.updated_at)).format('MM/DD/YYYY hh:mm');
          }
        })
        .catch(error => {
          console.log("getAllBankAcc() error", error.response);
        });
      },
      clear : function(){
        this.selectSite = undefined;
        this.selectBank = undefined;
        this.selectBankAcc = undefined;
        this.selectLevel = undefined;
        this.selectTransferType = undefined;
        this.dateValue = '';
        this.checkSwitch = '';
        this.getActionLogs();
      }
  }
};
</script>

<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
.my-class .dropdown-menu {
    max-height: 100px;
    overflow-y: auto;
  }
</style>
